<template>
  <component :is="component" :class="['n-icon', 'lni', 'lni-'+icon]" v-on="$listeners"></component>
</template>

<script>
import props from 'nast-ui/components/Icon/props'
import icons from './icons'

export default {
  name: 'NIcon',
  mixins: [ props, ],
  computed: {
    component() {
      return icons[this.icon] || 'i'
    },
  },
}
</script>

<style lang="scss">
@import url('https://cdn.lineicons.com/3.0/lineicons.css');
//@import "~@fortawesome/fontawesome-free/scss/fontawesome";
</style>
